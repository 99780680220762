export const initialAbility = [
    {
        action: 'read',
        subject: 'Auth',
    },
]

export const abilities = {
    administrator: [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'stats',
        },
        {
            action: 'manage',
            subject: 'documents',
        },
    ],
    'stadistics-admin': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'stats',
        },
    ],
    'archive-admin': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'manage',
            subject: 'documents',
        },
    ],
    'archive-reader': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'read',
            subject: 'documents',
        },
    ],
    'archive-creator': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'create',
            subject: 'documents',
        },
    ],
    'archive-editor': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'update',
            subject: 'documents',
        },
    ],
}

export const _ = undefined
