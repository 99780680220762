import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/documents/documents',
      name: 'documents',
      component: () => import('@/views/documents/Documents.vue'),
      meta: {
        pageTitle: 'Consulta Histórica',
        breadcrumb: [
          {
            text: 'Consulta Histórica',
            active: true,
          },
        ],
      },
    },
    {
      path: '/documents/documents',
      name: 'documents',
      component: () => import('@/views/documents/Documents.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Documentos',
          },
        ],
        pageTitle: 'Documentos',
        text: 'Documentos',
        resource: 'documents',
      },
    },
    {
      path: '/documents/edit/:id',
      name: 'documents-edit',
      component: () => import('@/views/documents/DocumentsEdit.vue'),
      meta: {
        action: 'update',
        breadcrumb: [
          {
            text: 'Documentos',
          },
        ],
        pageTitle: 'Editar Documentos',
        text: 'Editar Documentos',
        resource: 'documents',
      },
    },
    {
      path: '/documents/documents-add',
      name: 'documents-add',
      component: () => import('@/views/documents/DocumentsAdd.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Documentos',
          },
        ],
        pageTitle: 'Agregar Documentos',
        text: 'Agregar Documentos',
        resource: 'documents',
      },
    },

    // Stats
    {
      path: '/stats/roadents/list',
      name: 'reg-roadents-list',
      component: () => import('@/views/stats/RegRoadentsList.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Roedores',
          },
        ],
        pageTitle: 'Roedores',
        text: 'Roedores',
        resource: 'stats',
      },
    },
    {
      path: '/stats/roadents',
      name: 'reg-roadents',
      component: () => import('@/views/stats/RegRoadentsAdd.vue'),
      meta: {
        action: 'create',
        breadcrumb: [
          {
            text: 'Registro de Roedores',
          },
        ],
        pageTitle: 'Registro de Roedores',
        text: 'Registro de Roedores',
        resource: 'stats',
      },
    },
    {
      path: '/stats/roadents/:id',
      name: 'reg-roadents-edit',
      component: () => import('@/views/stats/RegRoadentsEdit.vue'),
      meta: {
        action: 'update',
        breadcrumb: [
          {
            text: 'Edicion de Roedores',
          },
        ],
        pageTitle: 'Edicion de Roedores',
        text: 'Edicion de Roedores',
        resource: 'stats',
      },
    },
    {
      path: '/stats/chagas/list',
      name: 'reg-chagas-list',
      component: () => import('@/views/stats/RegChagasList.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Chagas',
          },
        ],
        pageTitle: 'Chagas',
        text: 'Chagas',
        resource: 'stats',
      },
    },
    {
      path: '/stats/chagas',
      name: 'reg-chagas',
      component: () => import('@/views/stats/RegChagasAdd.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Registro de Chagas',
          },
        ],
        pageTitle: 'Registro de Chagas',
        text: 'Registro de Chagas',
        resource: 'stats',
      },
    },
    {
      path: '/stats/chagas/:id',
      name: 'reg-chagas-edit',
      component: () => import('@/views/stats/RegChagasEdit.vue'),
      meta: {
        action: 'update',
        breadcrumb: [
          {
            text: 'Edicion de Chagas',
          },
        ],
        pageTitle: 'Edicion de Chagas',
        text: 'Edicion de Chagas',
        resource: 'stats',
      },
    },
    {
      path: '/stats/leishmaniasi/list',
      name: 'reg-leishmaniasi-list',
      component: () => import('@/views/stats/RegLeishmaniasiList.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Leishmaniasis',
          },
        ],
        pageTitle: 'Leishmaniasis',
        text: 'Leishmaniasi',
        resource: 'stats',
      },
    },
    {
      path: '/stats/leishmaniasi',
      name: 'reg-leishmaniasi',
      component: () => import('@/views/stats/RegLeishmaniasiAdd.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Registro de Leishmaniasis',
          },
        ],
        pageTitle: 'Registro de Leishmaniasis',
        text: 'Registro de Leishmaniasi',
        resource: 'stats',
      },
    },
    {
      path: '/stats/leishmaniasi/:id',
      name: 'reg-leishmaniasi-edit',
      component: () => import('@/views/stats/RegLeishmaniasiEdit.vue'),
      meta: {
        action: 'updae',
        breadcrumb: [
          {
            text: 'Edicion de Leishmaniasis',
          },
        ],
        pageTitle: 'Edicion de Leishmaniasis',
        text: 'Edicion de Leishmaniasis',
        resource: 'stats',
      },
    },

    {
      path: '/stats/malaria/list',
      name: 'reg-malaria-list',
      component: () => import('@/views/stats/RegMalariaList.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Malaria',
          },
        ],
        pageTitle: 'Malaria',
        text: 'Malaria',
        resource: 'stats',
      },
    },
    {
      path: '/stats/malaria',
      name: 'reg-malaria',
      component: () => import('@/views/stats/RegMalariaAdd.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Registro de Malaria',
          },
        ],
        pageTitle: 'Registro de Malaria',
        text: 'Registro de Malaria',
        resource: 'stats',
      },
    },
    {
      path: '/stats/malaria/malariaEdit/:id',
      name: 'edit-malaria-edit',
      component: () => import('@/views/stats/RegMalariaEdit.vue'),
      meta: {
        action: 'read',
        breadcrumb: [
          {
            text: 'Editar Registro de Malaria',
          },
        ],
        pageTitle: 'Editar Registro de Malaria',
        text: 'Editar Registro de Malaria',
        resource: 'stats',
      },
    },


    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
