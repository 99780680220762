import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'

import {format, subMonths} from "date-fns";

// AG Grid Licence
import {LicenseManager} from 'ag-grid-enterprise'

LicenseManager.setLicenseKey("ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2")


// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.mixin({

  methods: {
    formatProcess(e) {
      const dateProcessed = format(e, 'yyyyMMdd')
      return dateProcessed
    },
    formatDisplay(e) {
      const dateformated = format(e, 'dd/MM/yyyy')
      return dateformated
    },
    getCurrentDateTime() {
      return format(new Date(), 'yyyyMMddd_kms')
    },
    onFirstDataRendered(params) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      params.columnApi.autoSizeColumns(allColumnIds);
    },
  },
});
